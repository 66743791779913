import React, {Component} from 'react';
import {LineChart, Line, XAxis, YAxis, ReferenceLine, Tooltip, Legend, ResponsiveContainer} from 'recharts';
import ReactGA from 'react-ga';
import CookieConsent from "react-cookie-consent";
import {Container} from "@material-ui/core";

class App extends Component {

    constructor() {
        super();
        this.state = {data: [], max: 0, maxHour: "00", min: 1000000, minHour: "00"};
    }

    componentDidMount() {
        initializeReactGA();
        fetch((process.env.NODE_ENV === 'development' ? 'data.json' : 'https://preciodelaenergia.es/.netlify/functions/node-fetch'), {
            method: 'GET',
            Origin: 'https://preciodelaenergia.es/',
            headers: {
                'Accept': 'application/json; application/vnd.esios-api-v1+json',
                'Content-Type': 'application/json',
            }
        })
            //.then(response => this.setState({data: response.json()}))
            .then(response => response.json())
            .then(data => this.setState({
                data: data.PVPC.map(function (hour) {
                    let tmp = {};
                    Object.keys(hour).forEach(function (key) {
                        if (key === 'PCB') {
                            tmp[key] = (Number(hour[key].replace(',', '.')) / 1000).toFixed(3)
                        }
                        if (key === 'Dia' || key === 'Hora') {
                            tmp[key] = hour[key].split("-")[0]
                        }
                    });
                    return tmp;
                })
            }))
            .catch(error => console.error('Error connecting to server: ' + error));
    }

    render() {

        this.state.data.forEach(element => {
            let value = Number(element.PCB);
            if (value >= this.state.max) {
                this.state.max = element.PCB;
                this.state.maxHour = element.Hora;
            }
            if (value <= this.state.min) {
                this.state.min = element.PCB;
                this.state.minHour = element.Hora;
            }
        });



        return (
            <div>
                <div>
                    <CookieConsent>Yes, we use cookies. If you don't like it change website, we won't miss
                        you!
                    </CookieConsent>

                </div>
                <Container>
                    <div id="content">
                        {<section className="row colset-2-its">
                            <h1 style={{textAlign: 'center', color: "#006699"}}>
                                <strong>Precio voluntario para el pequeño consumidor (PVPC)</strong>
                            </h1>
                            <br/>
                            <p>El 1 de abril de 2014 entró en vigor la normativa que regula el nuevo sistema para
                                determinar el coste de energía de la factura de electricidad, el Precio Voluntario para
                                el Pequeño Consumidor (PVPC)
                            </p>

                            <p>Precio máximo: {this.state.max}€/kWh de {this.state.maxHour}:00h a {parseInt(this.state.maxHour) + 1}:00h</p>
                            <p>Precio mínimo: {this.state.min}€/kWh de {this.state.minHour}:00h a {parseInt(this.state.minHour) + 1}:00h</p>

                            <ResponsiveContainer height={400}>
                                <LineChart width={1200} height={400} data={this.state.data} margin={{top: 20, right: 20, bottom: 5, left: 0}}>
                                    <Line type="monotone" dataKey="PCB" name="Precio" unit=" €/kWh" stroke="blue" />

                                    <XAxis dataKey="Hora"/>
                                    <ReferenceLine y={Math.max(...this.state.data.map(hour => hour.PCB))} label={{ position: 'top',  value: 'Precio máximo', fill: 'red' }} stroke="red" yAxis={'-0.5em'}/>
                                    <ReferenceLine y={Math.min(...this.state.data.map(hour => hour.PCB))} label={{ position: 'bottom',  value: 'Precio mínimo', fill: 'green' }} stroke="green" />
                                    <YAxis/>
                                    <Tooltip label={["00-01", "01-02"]}/>
                                    <Legend/>
                                </LineChart>
                            </ResponsiveContainer>
                        </section>}
                    </div>
                </Container>
            </div>
        );
    }
}

function initializeReactGA() {
  ReactGA.initialize('UA-1-1');
  ReactGA.pageview('/homepage');
}

export default App;
